<template>
  <div class="sign-bef">
    <el-row :gutter="10" v-if="edit">
      <el-col :span="10">
        <el-select v-model="dataForm.platform_code" placeholder="选择 签约前平台" :clearable="true">
          <el-option
              v-for="item in platformOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="9">
        <el-input v-model="dataForm.fans_total" placeholder="输入 签约前粉丝量" :clearable="true"
                  oninput="value=value.replace(/[^\d^\.^\-]/g,'')"/>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd" circle></el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table :data="tableData" style="width: 600px" size="mini">
          <el-table-column align="center" label="平台名称" prop="platform_name" min-width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.platform_name }}</span>
              <!--              <span class="platform_code">/{{ scope.row.platform_code }}</span>-->
            </template>
          </el-table-column>
          <el-table-column align="center" label="签约前粉丝量" prop="fans_total" min-width="200"></el-table-column>
          <el-table-column align="right" label="操作" width="100" v-if="edit">
            <template slot-scope="scope">
              <el-link
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row.id)"
              ><i class="el-icon-close"></i>移除</el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "BefPlatformInfo",
  data() {
    return {
      dataForm: {
        platform_code: '',
        platform_name: '',
        fans_total: '',
      },
      platformOptions: [],
    }
  },
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    edit: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    tableData() {
      return [...this.data]
    },
  },
  created() {
    this.getPlatforms()
  },
  methods: {
    async getPlatforms() {
      // 获取最新平台信息
      let { list } = await this.$api.getPlatforms()
      this.platformOptions = list
    },
    handleAdd() {
      let code = this.dataForm.platform_code
      let platform = this.platformOptions.find((value) => value.code === code)
      this.dataForm.platform_name = platform.name

      if (this.tableData.find((value) => value.platform_code === code)) {
        this.$notify.warning(`签约前平台[${ this.dataForm.platform_name }]信息已录入，若要修改，请删除后重新添加！！！`)
        return
      }

      this.tableData.push({ ...this.dataForm })
      this.$emit('currentInfo', this.tableData)
    },
    handleDelete(index) {
      this.tableData.splice(index, 1);
      this.$emit('currentInfo', this.tableData)
    },
  }
}
</script>

<style scoped>
.sign-bef {
  padding: 10px 20px;
  margin-bottom: 20px;
  border: 1px solid rgba(248, 248, 248, 1)
}
</style>
